import React from 'react'
import { useSelector } from 'react-redux'
import { StyledTitle } from './style'

const CoffeeTitle = () => {
  const coffee = useSelector((state) => state.coffee)

  if(coffee) {
    return (
      <StyledTitle>
        <strong>{coffee.producer_first_name}</strong> tem um recado para você!
      </StyledTitle>
    )
  }
  else return null
}

export default CoffeeTitle
