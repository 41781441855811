import React, { useEffect } from 'react'
import api from '../../services/api'
import { useDispatch } from 'react-redux'
import { updateCoffee } from '../../state/reducers/coffee'
import { showLoading, hideLoading } from '../../state/reducers/loading'

import Layout from '../../components/layout'
import Container from '../../components/container'
import CoffeeTitle from '../../components/title/coffeeTitle'
import Videos from '../../components/videos'
import Packages from '../../components/packages'
import HowTo from '../../components/howTo'
import Perks from '../../components/perks'
import Extras from '../../components/extras'

const Coffee = ({ params }) => {
  const dispatch = useDispatch();

  const requestCoffee = () => {
    dispatch(showLoading('Carregando...'))

    api.get(`/coffees/${params.id}`).then((response) => {
      if(response.data.coffee_bean){
        dispatch(updateCoffee(response.data.coffee_bean))
      }

      dispatch(hideLoading())
    }).catch(() => {
      dispatch(hideLoading())
    })
  }

  useEffect(() => {
    requestCoffee()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <Layout>
      <Container>
        <CoffeeTitle />
        <Videos origin="coffee" />
        <Packages origin="coffee" />
        <HowTo />
        <Perks />
        <Extras />
      </Container>
    </Layout>
  )
}

export const Head = () => {
  return(
    <title>
      Café
    </title>
  )
}

export default Coffee
